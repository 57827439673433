<template>
    <div id="contacts-enterprise"> 
      <contacts-list-enterprise></contacts-list-enterprise>
    </div>
  </template>
  
  <script>
    import ContactsListEnterprise from './components/ContactsListEnterprise'
  
    export default{
      name: 'ContactsEnterprise',
      components: {ContactsListEnterprise},
      beforeMount() {
        
      },
      methods: {
       
      },
    }
  </script>
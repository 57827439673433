<template>
    <div id="contacts-list"> 
      <b-row>
        <b-col cols="12">
          <b-card no-body class="mb-0">
            <div class="m-2">
              <b-row>  
  
                  <b-col xl="4" sm="12">
                    <b-form-group label="Fecha de Inicio" label-for="status">
                      <b-form-datepicker
                      locale="es"
                      id="date_ini" 
                      reset-button
                      placeholder="Fecha de inicio"
                      v-model="filtersForm.date_init"/>
                    </b-form-group>
                  </b-col>
  
                  <b-col xl="4" sm="12">
                    <b-form-group label="Fecha de Termino" label-for="status">
                      <b-form-datepicker
                      locale="es"
                      id="date_end" 
                      reset-button
                      placeholder="Fecha de Termino"
                      v-model="filtersForm.date_end"/>
                    </b-form-group>
                  </b-col>
  
                
   
              </b-row>
            </div>
          </b-card>
        </b-col>
      </b-row>
  
      <b-row>
        <b-col cols="12" class="mt-2">
          <b-card no-body class="mb-0">
            <div class="m-2">
              <b-overlay :show="overlayList" opacity="1.0" spinner-small spinner-variant="primary" @hidden="_onHidden()">
                <div class="d-flex align-items-end justify-content-end">
                  <b-overlay :show="overlayForm" opacity="0.5" spinner-small spinner-variant="primary" class="d-inline-block" @hidden="_onHidden()" >
                    <b-button class="mr-1" ref="button" variant="primary" :disabled="overlayForm" @click.prevent="_exportContactsEnterprise()" v-if="permissions_visible.download_contacts">
                      <feather-icon icon="DownloadIcon" class="mr-50"/>
                      <span class="align-middle">Descargar contactos</span>
                    </b-button>
                  </b-overlay>
                </div>
  
                
                <div class="scroller mb-1">
                  <b-table
                    hover
                    striped
                    show-empty
                    responsive="xl"
                    class="position-relative mb-1 mt-2 has-text-centered tablefix"
                    :items="contactsEnterprise.data"
                    :fields="tableColumns"
                    empty-text="Datos no Disponibles"
                  > 
                    
                    <template #cell(created_at)="data">{{ data.item.created_at | moment("DD/MM/YYYY") }}</template> 
  
                  </b-table>
                  <b-pagination align="right" v-model="currentPage" hide-goto-end-buttons :total-rows="contactsEnterprise.total" :per-page="contactsEnterprise.per_page" />
                </div>
              </b-overlay>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </template>
  
  <script>
    import {mapActions, mapState} from 'vuex'
    import ToastificationContent from "@core/components/toastification/ToastificationContent";
  
    export default {
      name: 'ContactsListNewsletter',
      data () {
        return {
        permissions_visible:{
          edit_contacts:false,
          show_contacts:false,
          download_contacts:false,
          edit_contacts:false,
        },
          overlayForm: false,
          overlayList: true,
          currentPage: 1,
          filtersForm: { 
            date_init:"",
            date_end:"",
            page: 1,  
          },
          tableColumns: [
            {key: 'id', sortable: false, label: 'ID'},
            {key: 'first_name', sortable: false, label: 'Nombre'},
            {key: 'last_name', sortable: false, label: 'Apellido'},
            {key: 'email', sortable: false, label: 'Email'},
            {key: 'phone', sortable: false, label: 'Teléfono'},
            {key: 'enterprise', sortable: false, label: 'Empresa'},
            {key: 'message', sortable: false, label: 'Mensaje'},
            {key: 'created_at', sortable: true, label: 'Fecha'},
          ],
        }
      },
      mounted(){
        this.permissions_visible = this.$PermissionHelper.checkPermissions(this.permissions_visible);
      },
      beforeMount() { 
        this._fetchContactsEnterprise(this.filtersForm)
      },
      watch: {
        "filtersForm.date_end": {handler(newval){this._fetchContactsEnterprise(this.filtersForm)}},
        currentPage (val) {
          this.filtersForm.page = this.currentPage
          this._fetchContactsEnterprise(this.filtersForm)
        }
      },
      computed: { 
        ...mapState('contacts',['contactsEnterprise']),
      },
      methods: {
        ...mapActions('contacts',['fetchContactsEnterprise','exportContactsEnterprise']),
        async _exportContactsEnterprise() {
          this.overlayForm = true
          await this.exportContactsEnterprise(this.filtersForm)
          .then(res => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Se ha enviado un correo con el archivo',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            })
          }).catch(err => {
            console.log(err);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Problemas al exportar el archivo',
                icon: 'AlertCircleIcon',
                variant: 'danger',
              },
            })
          }).finally(() => {
            this.overlayForm = false
          })
        }, 
        
        async _fetchContactsEnterprise(filter) {
          this.overlayList = true
          await this.fetchContactsEnterprise(filter)
          .then((res) => {
             
          })  
          .finally(() => {
            this.overlayList = false
          })
        },
   
          
        _editContact(newobj) {
          //let data = { name: 'news-details', params: { id: newobj.id, newsingle: newobj }};
          //this.$router.push( data )
        },
        _onHidden() {
          //this.$refs.button.focus()
        }, 
      },
    }
  </script>
  <style>
  .tablefix tr{
    height: 61px !important;
  } 

  .table th, .table td {
    padding: 0.72rem !important;
  }
  </style>